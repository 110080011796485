<template>
  <div class="container">
    <PublicHeader :totalName="$t('withAddress').title" />
    <!-- 输入框 -->
    <div class="content">
      <div class="form">
        <div class="item">
          <div class="lable">
            <div class="name">TRC20</div>
            <div class="txt" @click="toEditAddress('TRC20', userInfo.TRC20Address)"><span>{{ $t('withAddress').edit
                }}</span><img src="@/assets/img/edit.png" width="14" />
            </div>
          </div>
          <div class="address">
            <div class="txt">{{ userInfo.TRC20Address }}</div>
            <span @click="onCopy(userInfo.TRC20Address)">{{ $t('withAddress').copy }}</span>
          </div>
        </div>
        <div class="item">
          <div class="lable">
            <div class="name">ERC20</div>
            <div class="txt" @click="toEditAddress('ERC20', userInfo.ERC20Address)"><span>{{ $t('withAddress').edit
                }}</span><img src="@/assets/img/edit.png" width="14" /></div>
          </div>
          <div class="address">
            <div class="txt">{{ userInfo.ERC20Address }}</div>
            <span @click="onCopy(userInfo.ERC20Address)">{{ $t('withAddress').copy }}</span>
          </div>
        </div>
        <div class="item">
          <div class="lable">
            <div class="name">OMNI</div>
            <div class="txt" @click="toEditAddress('OMNI', userInfo.OMNIAddress)"><span> {{ $t('withAddress').edit
                }}</span><img src="@/assets/img/edit.png" width="14" /></div>
          </div>
          <div class="address">
            <div class="txt">{{ userInfo.OMNIAddress }}</div>
            <span @click="onCopy(userInfo.OMNIAddress)">{{ $t('withAddress').copy }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 修改地址 -->
    <van-popup v-model="showEdit" closeable round :style="{ width: '320px', borderRadius: '10px' }">
      <div class="popInfo">
        <div class="title"> {{ $t('withAddress').edit }}{{ WalletNetworkType }}</div>
        <div class="info">
          <van-field v-model="Address" rows="4" type="textarea" :placeholder="$t('withAddress').addressMsg"></van-field>
          <div class="tip">* {{ $t('withAddress').tips }}</div>
          <div class="btns">
            <div class="btn">{{ $t('public').cancel }}</div>
            <div class="btn2" @click="handleSub">{{ $t('public').confirm }}</div>
          </div>
        </div>

      </div>

    </van-popup>
  </div>
</template>

<script>
import userApi from '@/api/user'
import { mapGetters } from 'vuex'
import { getRandomString } from '@/utils/validate'
import PublicHeader from '@/components/publicHeader'
import { color } from 'echarts';

export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      userInfo: {},
      showEdit: false,
      Address: '',
      WalletNetworkType: '',
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getAccountBase()
  },
  methods: {
    // 获取用户信息
    async getAccountBase() {
      const res = await userApi.getAccountBase()
      this.userInfo = res.Data
    },
    toEditAddress(WalletNetworkType, val) {
      this.WalletNetworkType = WalletNetworkType
      this.showEdit = true
      this.Address = val
    },
    //复制方法
    onCopy(text) {
      return new Promise((resolve) => {
        if (navigator.clipboard?.writeText) {
          this.$toast(this.$t('public').copySuccess)
          return resolve(navigator.clipboard.writeText(text))
        }
        const textarea = document.createElement('textarea')
        document.body.appendChild(textarea)
        textarea.style.position = 'absolute'
        textarea.style.clip = 'rect(0 0 0 0)'
        textarea.value = text
        textarea.select()
        document.execCommand('copy', true)
        textarea.remove()
        this.$toast(this.$t('public').copySuccess)
        return resolve(true)
      })
    },
    // 修改地址
    async handleSub() {
      if (!this.Address) {
        this.$toast({ message: this.$t('withAddress').addressMsg })
        return false
      }
      const form = {
        WalletNetworkType: this.WalletNetworkType,
        Address: this.Address,
      }
      await userApi.UpdateWalletAddress(form)
      this.showEdit = false
      this.getAccountBase()
      this.$toast({ message: this.$t('withAddress').succss })
    },
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 100vh;
  width: 100%;
  padding: 60px 15px 0 15px;
  background: #ffffff;

  .content {
    .form {
      .lable {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 10px 0;

        .name {
          font-weight: bold;
          font-size: 16px;
          color: #333333;
        }

        .txt {
          display: flex;
          align-items: center;

          span {
            margin-right: 5px;
            font-weight: 500;
            font-size: 14px;
            color: #999999;
          }
        }
      }

      .address {
        height: 41px;
        background: #F2F2F2;
        border-radius: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        .txt {
          width: 260px;
          font-size: 13px;
          color: #333333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        span {
          border-left: 1px solid #D8D8D8;
          height: 20px;
          display: flex;
          align-items: center;
          padding-left: 10px;
          font-size: 13px;
          color: #3485FF;
        }
      }
    }
  }

  .popInfo {
    .title {
      padding: 20px 0;
      text-align: center;
      font-weight: bold;
      font-size: 16px;
      color: #333333;
      border-bottom: 1px solid #DCDCDC;
    }

    .info {
      padding: 15px 13px 20px 13px;
      text-align: center;

      .tip {
        padding: 10px 0 0 0;
        font-size: 14px;
        color: #FF9B37;
      }

      .btns {
        margin: 20px 13px 0 13px;
        display: flex;
        justify-content: space-between;

        .btn {
          width: 119px;
          height: 40px;
          background: rgba(201, 43, 46, 0);
          border: 1px solid #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #C92B2E;
        }

        .btn2 {
          width: 119px;
          height: 40px;
          background: #C92B2E;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 13px;
          color: #fff;
        }
      }
    }
  }
}

::v-deep .van-popup__close-icon {
  color: #000000;
}

::v-deep .van-field__value {
  overflow: visible;
  display: grid;
}

::v-deep .van-button__content {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}

.van-cell {
  background: #F2F2F2;
  padding: 10px;
  border-radius: 5px;
  font-size: 15px;
}

::v-deep .van-field__right-icon {
  margin: -10px 8px;
  padding: 0px;
  line-height: normal;
}
</style>
